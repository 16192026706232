<template>
  <div class="details">
    <div class="pointer header" @click="view = !view">
      <h1>
        <div class="box-icon">
          <img src="../../assets/icons/help/patients.png" alt="" />
        </div>
        Profissionais
      </h1>
      <img
        class="pointer"
        src="../../assets/icons/settings/close.png"
        alt=""
        v-if="view"
      />
      <img class="pointer" src="../../assets/icons/settings/open.png" alt="" v-else />
    </div>
    <div class="content" v-if="view">
      <!-- TODO: Novos Agendamentos -->
      <hr class="hr-top" :style="viewSubItems ?? 'margin-bottom: 8px'" />
      <div class="item" v-for:="item in items">
        <div
          class="item-card"
          :style="isSubItem(item.obj)"
          v-if="viewSubItems === item.obj || viewSubItems === null"
        >
          <p>{{ item.label }}</p>
          <button
            class="btn-preview"
            :style="viewSubItems === item.obj ? 'color: #828282;' : 'color: #2F80ED;'"
            @click="changeViewSubItems(item.obj)"
          >
            {{ viewSubItems === item.obj ? "sair" : "ver previa" }}
            <img
              src="../../assets/icons/help/close.png"
              v-if="viewSubItems === item.obj"
            />
            <img src="../../assets/icons/help/down.png" v-else />
          </button>
        </div>

        <div class="box-card" v-if="viewSubItems === item.obj">
          <div class="flex flex-column">
            <p class="txt-gray mt-20">
              Como paciente, é importante que estejamos cientes dos possíveis problemas que podemos enfrentar no atendimento médico. Abaixo, responderemos algumas perguntas frequentes sobre esse assunto.
            </p>

            <p class="txt-gray mt-14" :style="{fontWeight: 'bold !important'}">
              1. O que fazer quando há dificuldade em marcar uma consulta?
            </p>
            <p class="txt-gray mt-8">
              É importante que você verifique os locais, datas e horários disponibilizados pelo profissional com a Ommed. Caso não haja disponibilidade, você poderá então se colocar em "Lista de espera", assim garantindo que quando houver uma possibilidade, será marcado o quanto antes.
            </p>

            <b class="txt-gray mt-14" :style="{fontWeight: 'bold !important'}">
              2. O que fazer quando há dificuldades em entender ou seguir as orientações médicas?
            </b>
            <p class="txt-gray mt-8">
              Se você está enfrentando dificuldades em entender ou seguir as orientações médicas, é importante solicitar ao profissional de saúde orientações claras e objetivas, além de perguntar sobre material informativo sobre o tratamento e suas etapas.
            </p>
            <p class="txt-gray mt-4">
              Em resumo, como paciente, é importante estarmos atentos aos possíveis problemas que podemos enfrentar no atendimento médico e buscarmos soluções e alternativas para garantir o nosso bem-estar e saúde.
            </p>
            <div class="flex align-items-center justify-content-between p-0 mt-52">
              <div class="flex align-items-center p-0" style="gap: 8px">
                <img class="btn-like" src="../../assets/icons/help/like.png" alt="" />
                <img class="btn-like" src="../../assets/icons/help/deslike.png" alt="" />
              </div>
              <button class="btn-article">
                Ver artigo completo
                <img src="../../assets/icons/help/share.png" alt="" />
              </button>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  // onMounted
} from "vue";
// import imagem1 from "../../assets/images/profile/1.png";
// import imagem2 from "../../assets/images/profile/2.png";
// import imagem3 from "../../assets/images/profile/3.png";
export default {
  emits: {},
  setup() {
    const view = ref(false);
    const viewSubItems = ref(null);
    const options = ref({
      shared_content: false,
      partners_access: false,
    });
    const items = ref([
      {
        label: "Problemas com atendimento",
        obj: "problems_service",
      },
      {
        label: "Dúvidas com agendamento",
        obj: "scheduling_questions",
      },
      {
        label: "Reagendamento",
        obj: "reschedule",
      },
      {
        label: "Exames e encaminhamentos",
        obj: "exams_referrals",
      },
    ]);

    // onMounted(() => {
    //   console.log("montou");
    //   console.log("options: ", options.value);
    //   console.log("items:", items.value);
    // });

    const changeViewSubItems = (strObj) => {
      if (viewSubItems.value === strObj) {
        viewSubItems.value = null;
      } else {
        viewSubItems.value = strObj;
      }
    };

    const isSubItem = (item) => {
      if (item === viewSubItems.value) {
        return "margin: 0; border-radius: 0;padding-left: 30px;padding-right: 30px;padding-top: 28px;";
      } else {
        if (viewSubItems.value === null) {
          return "margin: 0 18px 8px;";
        } else {
          return "margin: 0;";
        }
      }
      return;
    };

    return {
      view,
      options,
      items,
      viewSubItems,
      changeViewSubItems,
      isSubItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.details {
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0px 18px;
    h1 {
      display: flex;
      align-items: center;
      gap: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }
  .content {
    .item {
      display: flex;
      flex-direction: column;
      p {
        color: #4f4f4f;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
      }
      .right {
        display: flex;
        align-items: center;
        gap: 29px;
        margin-right: 6px;
      }
      .sub-items {
        display: flex;
        justify-content: space-between;
        padding: 8px 50px;
        margin-top: 10px;
        border-bottom: 1px solid #f2f2f2;
        p {
          color: #4f4f4f;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
        }
        .right {
          display: flex;
          align-items: center;
          gap: 29px;
          margin-right: 6px;
        }
      }
      &:last-child {
        margin-bottom: 8px;
      }
    }
    .container-radios {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: #fafafa;
      height: 110px;
    }
    .profile {
      height: 40px;
      width: 40px;
    }
    .name {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #4f4f4f;
      margin-left: 8px;
    }
  }
}
.no-border-bottom {
  border-bottom: none !important;
}
.btn {
  width: max-content;
  height: 40px;
}
.pointer {
  cursor: pointer;
}
.box-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.item-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  .btn-preview {
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
    color: #2f80ed;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background: white;
    border-radius: 80px;
    border: none;
    padding: 4px 12px;
    cursor: pointer;
  }
}
.box-card {
  background: #fafafa;
  transform: translate(0, -12px);
  padding: 12px;
  margin-bottom: -20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .flex,
  .flex-column {
    padding: 0 18px;
  }
}
.btn-like {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #828282;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 8px 24px rgb(61 71 102 / 8%), 0px 3px 6px rgb(61 71 102 / 6%);
  }
}
.btn-article {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2f80ed;
  padding: 10px 20px;
  width: max-content;
  background: none;
  border: 1px solid #2f80ed;
  border-radius: 80px;
  gap: 8px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 8px 24px rgb(61 71 102 / 8%), 0px 3px 6px rgb(61 71 102 / 6%);
  }
}
.txt-gray {
  font-family: "Roboto";
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #828282 !important;
}
.mt-40 {
  margin-top: 40px;
}
.mt-52 {
  margin-top: 52px;
}
.mt-4 {
  margin-top: 4px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.hr-top {
  border-color: #e0e0e0;
  margin: 0 16px;
}
hr {
  margin-top: 40px;
  border-color: #2f80ed;
}
::v-deep(.p-radiobutton-box) {
  border: 1px #bdbdbd solid;
}
::v-deep(.field-radiobutton) {
  margin-bottom: 0px;
}
</style>
